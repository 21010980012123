import jQuery from 'jquery';

//--------------------------------------------------
// Video

jQuery(function ($) {
  if ($('.video, video-embed').length) {
    if ($('video-embed').length) {
      $(document).on('click', 'video-embed button', function (e) {
        e.preventDefault();
        let target = e.target;
        if (target.nodeName.toLowerCase() !== 'button') {
          //find closesest parent el which is a button without using jquery
          while (target && target.nodeName.toLowerCase() !== 'button') {
            target = target.parentNode;
          }
        }
        var url = target.dataset.videourl;
        var video = target.previousElementSibling;

        if (url.includes('vimeo')) {
          var vimeo_id = url.split('vimeo.com/')[1];
          if (video && vimeo_id) {
            video.src =
              'https://player.vimeo.com/video/' + +vimeo_id + '?autoplay=1';
          }
        } else if (url.includes('youtu')) {
          var youtube_long = url.split('watch?v=')[1];
          var youtube_short = url.split('youtu.be/')[1];
          var youtube_id;

          if (youtube_long) {
            youtube_id = youtube_long;
          }

          if (youtube_short) {
            youtube_id = youtube_short;
          }
          if (youtube_id && video) {
            video.src =
              'https://www.youtube.com/embed/' + youtube_id + '?autoplay=1';
          }
        }
      });
    }
  }
});
